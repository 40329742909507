<!--安全管理-围岩判识-西南交大-->
<template>
  <div>
    <div style="width: 100%;height: 100vh;margin: auto">
      正在跳转
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Encrypt, Decrypt} from "@/utils/secret";

export default {
  name: "index",
  computed: {
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_USER']),
  },
  data(){
    return{
      rockuser: '',
      rockpws: ''
    }
  },
  created() {
    this.axios.post('/userinfo/getRockId', (response) => {
      if (response.obj !== null){
        this.axios.post('/jointRock/getUserPws', (responseRock) => {
          if (responseRock.obj){
            this.rockuser = responseRock.obj.rockuser;
            this.rockpws = responseRock.obj.rockpws;
            let time = new Date().getTime() / 1000;
            let url = 'http://tric.swjtu.edu.cn/ApiCrcc?username=' + this.rockuser + '&pws=' + this.rockpws + '&time=' + time;
            window.open(url, '_blank');
            this.$router.go(-1);
          }
        },{
          id: response.obj
        })
      }
    },{
      userid: this.GET_USERID
    })
  },
  methods:{

  }
}
</script>

<style scoped>

</style>